import { useContext } from "react"
import { Container, Box } from "@mui/material"
import Categories from "./Categories"
import Slider from "./Sliderv3"
import Benefits from "./Benefits"
// import BusinessVersionIntroText from "./BusinessVersionIntroText"
import Offers from "./Offers"
import BlogPreview from "./BlogPreview"
import Brands from "./Brands"

import { RawBrandCategory } from "../../utils/commerceTools/graphql/queries/fetchBrandCategories"

import AppContext from "@secureo/common/context/AppContext/AppContext"
import { AllBlogs } from "../../typings/Content"

interface Props {
	brandCategories: RawBrandCategory[]
	allBlogs: AllBlogs
}

const Homepage = ({ brandCategories, allBlogs }: Props) => {
	const { language, countryCode, currencyCode } = useContext(AppContext)

	return (
		<>
			<Slider language={language} />
			<Box pb={4} style={{ background: "white" }}>
				<Benefits currencyCode={currencyCode} />
				{countryCode !== "PL" && <Categories />}
			</Box>
			<Container>
				{/* {!isBusinessVersion ? <Offers /> : <BusinessVersionIntroText />} */}
				<Offers language={language} />
				{language === "de" && <BlogPreview allBlogs={allBlogs} />}
				{language === "de" && <Brands brandCategories={brandCategories} />}
			</Container>
		</>
	)
}

export default Homepage
