import { GetStaticProps } from "next"
import { getServerTranslations } from "utils/i18n/getServerTranslations"
import SEO from "components/SEO"
import Homepage from "containers/HomePage/index"

import { anonymousAccessTokenCache } from "@secureo/common/utils/commerceTools/token/accessTokenCache"
import fetchBrandCategories, {
	RawBrandCategory,
} from "utils/commerceTools/graphql/queries/fetchBrandCategories"
import fetchLatestMasterRef from "utils/prismic/fetchLatestMasterRef"
import fetchAllBlogs from "utils/prismic/graphql/queries/fetchAllBlogs"

import { AllBlogs } from "typings/Content"

const IndexPage = ({ brandCategories, allBlogs }: Props) => {
	return (
		<>
			<SEO pageType="home" asPath={"/"} />
			<Homepage brandCategories={brandCategories} allBlogs={allBlogs} />
		</>
	)
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
	const accessToken = await anonymousAccessTokenCache()
	const { brandCategories } = await fetchBrandCategories(accessToken)

	const masterRef = await fetchLatestMasterRef()
	const allBlogs = await fetchAllBlogs(masterRef)

	const props: Props = {
		brandCategories,
		allBlogs,
		...(await getServerTranslations(locale, ["common", "countries", "languages", "home"])),
	}

	return {
		props,
	}
}

interface Props {
	brandCategories: RawBrandCategory[]
	allBlogs: AllBlogs
}

export default IndexPage
