import { useContext } from "react"
import { Box, Container, Grid } from "@mui/material"

import { makeStyles } from "makeStyles"

import Link from "components/i18n/Link"

import AppContext from "@secureo/common/context/AppContext/AppContext"
import Button from "components/Buttons/Button"

import Tresore from "svg/homepage/categories/tresore.svg"
import EDoorLocks from "svg/homepage/categories/eDoorLocks.svg"
import VideoCameras from "svg/homepage/categories/cameras.svg"
import BurglaryRiskProtection from "svg/homepage/categories/burglaryRiskProtection.svg"
import FireSafety from "svg/homepage/categories/fireSafety.svg"
import Outdoor from "svg/homepage/categories/outdoor.svg"
import ClosingCylinder from "svg/homepage/categories/closingCylinder.svg"
import AlarmSystem from "svg/homepage/categories/alarmSystem.svg"
import SmartLocks from "svg/homepage/categories/smart-locks.svg"
import BusinessCustomer from "svg/homepage/categories/businessCustomer.svg"

import useTranslation from "hooks/useTranslation"

const useStyles = makeStyles()({
	gridItemWrapper: {
		// -webkit-box-sizing: initial;
		// -moz-box-sizing: initial;
		boxSizing: "initial",
		background: "#F7FAFA",
		border: "1px solid #7abfac",
		borderRadius: "6px",
		margin: "0",
		height: "100%",
		width: "100%",
		overflow: "hidden",
		cursor: "pointer",
		"&:hover": {
			background: "rgba(122, 191, 172, 0.2)",
		},
	},
	icon: {
		color: "#7abfac",
		alignItems: "flex-start",
		height: "40px",
		padding: "15px",
		paddingBottom: "5px",
	},
	title: {
		paddingBottom: "5px",
	},
})

export default function GroupedButtons() {
	const { classes } = useStyles()
	const { t } = useTranslation("common")
	const { isBusinessVersion, dispatch, language } = useContext(AppContext)

	const togglePrivateBusiness = () => {
		dispatch({ type: isBusinessVersion ? "SET_DEFAULT_VERSION" : "SET_BUSINESS_VERSION" })
	}

	const title = isBusinessVersion ? t("privateCustomers") : t("businessCustomers")

	const categoriesByLanguage = {
		de: [
			{
				name: "Tresore & Safes",
				icon: <Tresore className={classes.icon} />,
				link: "/pc/tresore-safes",
			},
			{
				name: "E-Türschlösser",
				icon: <EDoorLocks className={classes.icon} />,
				link: "/c/e-tuerschloesser",
			},
			{
				name: "Videokameras",
				icon: <VideoCameras className={classes.icon} />,
				link: "/c/videoueberwachung",
			},
			{
				name: "Gefahrenmelder",
				icon: <BurglaryRiskProtection className={classes.icon} />,
				link: "/c/gefahrenmelder",
			},
			{
				name: "Feuerschutz",
				icon: <FireSafety className={classes.icon} />,
				link: "/c/feuerfeste-tresore",
			},
			{
				name: "Outdoor",
				icon: <Outdoor className={classes.icon} />,
				link: "/pc/eingangsbereich-garten",
			},
			{
				name: "Schließzylinder",
				icon: <ClosingCylinder className={classes.icon} />,
				link: "/c/schliesszylinder",
			},
			{
				name: "Alarmanlagen",
				icon: <AlarmSystem className={classes.icon} />,
				link: "/c/alarmanlagen",
			},
			{
				name: "Smart Locks",
				icon: <SmartLocks className={classes.icon} />,
				link: "/c/smart-locks",
			},
		],
		pl: [
			{
				name: "Sejfy i szafy",
				icon: <Tresore className={classes.icon} />,
				link: "/pc/tresore-safes",
			},
			{
				name: "Zamki elektroniczne",
				icon: <EDoorLocks className={classes.icon} />,
				link: "/c/e-tuerschloesser",
			},
			{
				name: "Monitoring wizyjny",
				icon: <VideoCameras className={classes.icon} />,
				link: "/c/videoueberwachung",
			},
			{
				name: "Systemy alrmowe",
				icon: <BurglaryRiskProtection className={classes.icon} />,
				link: "/c/gefahrenmelder",
			},
			{
				name: "Sejfy ognioodporne",
				icon: <FireSafety className={classes.icon} />,
				link: "/c/feuerfeste-tresore",
			},
			{
				name: "Outdoor",
				icon: <Outdoor className={classes.icon} />,
				link: "/pc/eingangsbereich-garten",
			},
			{
				name: "Wkładki bębenkowe",
				icon: <ClosingCylinder className={classes.icon} />,
				link: "/c/schliesszylinder",
			},
			{
				name: "Systemów alarmowych",
				icon: <AlarmSystem className={classes.icon} />,
				link: "/c/alarmanlagen",
			},
			{
				name: "Inteligentne zamki",
				icon: <SmartLocks className={classes.icon} />,
				link: "/c/smart-locks",
			},
		],
	}

	const categories = categoriesByLanguage[language] ?? categoriesByLanguage.de

	return (
		<Box mt={3}>
			<Container>
				<Grid container spacing={3}>
					{categories.map((category) => {
						const { name, icon, link } = category

						return (
							<Grid
								item
								xs={6}
								sm={3}
								md={2.4}
								key={category.name}
								style={{ textAlign: "center" }}
							>
								<Link href={link}>
									<div className={classes.gridItemWrapper}>
										<div>{icon}</div>
										<div className={classes.title}>{name}</div>
									</div>
								</Link>
							</Grid>
						)
					})}
					<Grid item xs={6} sm={9} md={2.4} style={{ textAlign: "center" }}>
						<Button onClick={togglePrivateBusiness} style={{ background: "#7abfac" }}>
							<div
								className={classes.gridItemWrapper}
								style={{
									background: "#7abfac",
									color: "#f7fafa",
								}}
							>
								<BusinessCustomer
									className={classes.icon}
									style={{
										height: "40px",
									}}
								/>
								<div className={classes.title}>{title}</div>
							</div>
						</Button>
					</Grid>
				</Grid>
			</Container>
		</Box>
	)
}
