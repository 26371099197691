import { Box, Button, Grid } from "@mui/material"
import { createCloudinaryLegacyURL } from "@cloudinary/url-gen"

import Link from "components/i18n/Link"

import useTranslation from "hooks/useTranslation"

import { makeStyles } from "makeStyles"

const useStyles = makeStyles()((theme) => ({
	root: {
		flexGrow: 1,
	},
	button: {
		padding: theme.spacing(2),
		textAlign: "left",
		color: "white",
		height: "230px",
		width: "100%",
		backgroundRepeat: "no-repeat",
		background: "whitesmoke",
		borderRadius: "0",
	},
	title: {
		position: "absolute",
		top: "0",
		left: "20px",
		paddingTop: "15px",
		fontSize: "24px",
		fontWeight: "bold",
		textTransform: "uppercase",
	},
	bottomLine: {
		position: "absolute",
		bottom: "0",
		left: "20px",
		maxWidth: "220px",
		paddingBottom: "15px",
	},
}))

const images = [
	createCloudinaryLegacyURL("Homepage/wertschutzschraenke-cut.png", {
		cloud_name: process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME,
		secure: true,
		transformation: [
			{ height: 230 },
			{ crop: "fill" },
			{ dpr: "auto" },
			{ fetchFormat: "auto" },
			{ quality: "auto" },
		],
	}),
	createCloudinaryLegacyURL("Homepage/waffenschraenke-cut.png", {
		cloud_name: process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME,
		secure: true,
		transformation: [
			{ height: 230 },
			{ crop: "fill" },
			{ dpr: "auto" },
			{ fetchFormat: "auto" },
			{ quality: "auto" },
		],
	}),
	createCloudinaryLegacyURL("Homepage/e-tuerschloesser.png", {
		cloud_name: process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME,
		secure: true,
		transformation: [
			{ height: 230 },
			{ crop: "fill" },
			{ dpr: "auto" },
			{ fetchFormat: "auto" },
			{ quality: "auto" },
		],
	}),
]

const TopCategories = ({ language }: Props) => {
	const { classes } = useStyles()
	const { t } = useTranslation("home")

	return (
		<Box my={3}>
			<div className={classes.root}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={4}>
						<Link
							href={
								language === "pl" ? "/c/value-cabinets" : "/c/wertschutzschraenke"
							}
						>
							<Button className={classes.button} style={{ background: "#FFD462" }}>
								<img
									src={images[0]}
									alt=""
									style={{
										position: "absolute",
										maxHeight: "230px",
										objectFit: "cover",
										right: 0,
									}}
								/>
								<div className={classes.title}>{t("valuableSafetyCabinets")}</div>
								<div className={classes.bottomLine}>{t("royalClassOfSafes")}</div>
							</Button>
						</Link>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<Link href={language === "pl" ? "/c/gun-safes" : "/c/waffenschraenke"}>
							<Button className={classes.button} style={{ background: "#7ABFAC" }}>
								<img
									src={images[1]}
									alt=""
									style={{
										position: "absolute",
										maxHeight: "230px",
										objectFit: "cover",
										right: 0,
									}}
								/>
								<div className={classes.title}>{t("gunCabinets")}</div>
								<div className={classes.bottomLine}>
									{t("forStoringLongAndShortGuns")}
								</div>
							</Button>
						</Link>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<Link href={language === "pl" ? "/c/e-door-locks" : "/c/e-tuerschloesser"}>
							<Button className={classes.button} style={{ background: "#1B5C71" }}>
								<img
									src={images[2]}
									alt=""
									style={{
										position: "absolute",
										maxHeight: "230px",
										objectFit: "cover",
										right: 0,
									}}
								/>
								<div className={classes.title}>{t("electronicDoorLocks")}</div>
								<div className={classes.bottomLine}>{t("keylessDoorOpening")}</div>
							</Button>
						</Link>
					</Grid>
				</Grid>
			</div>
		</Box>
	)
}

interface Props {
	language: string
}

export default TopCategories
