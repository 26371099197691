import {
	Box,
	Grid,
	Card,
	CardActionArea,
	CardMedia,
	CardContent,
	Typography,
	Button,
} from "@mui/material"
import { makeStyles } from "makeStyles"
import Link from "components/i18n/Link"

import { AllBlogs } from "typings/Content"

const useStyles = makeStyles()({
	card: {
		maxWidth: 345,
		// minWidth: "100%",
		boxShadow: "0px 5px 10px 0px rgba(0,0,0,0.25)",
		background: "white",
		margin: "auto",
		zIndex: 2,
		position: "absolute",
		marginLeft: "auto",
		marginRight: "auto",
		left: 0,
		right: 0,
		textAlign: "left",
	},
	backgroundElement: {
		height: 270,
		width: "85%",
		background: "whitesmoke",
		boxShadow: "0px 5px 10px 0px rgba(0,0,0,0.25)",
		position: "absolute",
		marginLeft: "auto",
		marginRight: "auto",
		left: 0,
		right: 0,
		top: 50,
		borderRadius: 3,
		textAlign: "center",
		margin: "auto",
	},
	"@media only screen and (min-width: 1000px)": {
		backgroundElement: {
			width: 450,
		},
		card: {
			maxWidth: 345,
		},
	},
	"@media only screen and (max-width: 1000px)": {
		backgroundElement: {
			width: "70%",
		},
		card: {
			maxWidth: "60%",
		},
	},
	"@media only screen and (max-width: 815px)": {
		backgroundElement: {
			width: "85%",
		},
		card: {
			maxWidth: "70%",
		},
	},
	"@media only screen and (max-width: 555px)": {
		backgroundElement: {
			width: "95%",
		},
		card: {
			maxWidth: "80%",
		},
	},
	media: {
		height: 140,
	},
})

const BlogPreview = ({ allBlogs }: Props) => {
	const { classes } = useStyles()
	const { edges = [] } = allBlogs

	return (
		<Box mb={5} pt={5}>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={5} md={5}>
					<Box mb={3}>
						<h2>Blog</h2>
						<p>
							Auf unserem Blog dreht sich alles um Ihre Sicherheit. Erfahren Sie, wie
							Sie Ihr Zuhause außen wie auch innen sicher gestalten können.
						</p>
						<p>
							Mit unseren Sicherheitsratgebern verhindern Sie jegliche
							Einbruchsmöglichkeit und schaffen ein sicheres Heim für Sie und Ihre
							Familie.
						</p>
						<Box mt={3}>
							<Link href="/blog">
								<Button
									variant="contained"
									color="primary"
									style={{ color: "white" }}
								>
									Alle ansehen
								</Button>
							</Link>
						</Box>
					</Box>
				</Grid>
				<Grid
					item
					xs={12}
					sm={7}
					md={7}
					style={{
						position: "relative",
						textAlign: "center",
						margin: "auto",
						height: 380,
					}}
				>
					<div className={classes.backgroundElement} />
					{edges.map((edge) => {
						const {
							node: {
								_meta: { uid },
								shortdescription,
								previewimage: { url },
							},
						} = edge
						const title = edge.node.title[0].text

						return (
							<Card
								className={classes.card}
								key={uid}
								style={{ background: "white" }}
							>
								<Link href={`/blog/${uid}`}>
									<CardActionArea>
										<CardMedia
											className={classes.media}
											image={url}
											title="Contemplative Reptile"
										/>
										<CardContent>
											<Typography
												gutterBottom
												variant="h6"
												component="h3"
												style={{ lineHeight: 1.2 }}
											>
												<strong>{title}</strong>
											</Typography>
											<Box my={2}>
												<Typography
													variant="body2"
													color="textSecondary"
													component="p"
												>
													{shortdescription}
												</Typography>
											</Box>
										</CardContent>
									</CardActionArea>
								</Link>
								<Box
									m={1}
									style={{
										textAlign: "center",
									}}
								>
									<Link href={`${"/blog/"}${uid}`}>
										<Button size="small" color="primary" fullWidth>
											Lesen
										</Button>
									</Link>
								</Box>
							</Card>
						)
					})}
				</Grid>
			</Grid>
		</Box>
	)
}

interface Props {
	allBlogs: AllBlogs
}

export default BlogPreview
