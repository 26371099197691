import { Paper, Grid, Box } from "@mui/material"
import Slider from "react-slick"

import Link from "components/i18n/Link"

import useTranslation from "hooks/useTranslation"

import { makeStyles } from "makeStyles"

import { RawBrandCategory } from "utils/commerceTools/graphql/queries/fetchBrandCategories"

const useStyles = makeStyles()((theme) => ({
	paper: {
		padding: theme.spacing(0),
		minHeight: "55px",
		textAlign: "center",
		color: theme.palette.secondary.dark,
		boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.15)",
		margin: "5px",
	},
	brandLogo: {
		border: "1px solid whitesmoke",
		borderRadius: 3,
		textAlign: "center",
		height: 70,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		objectFit: "contain",
		filter: "grayscale(100%)",
		transition: "0.3s",
		"&:hover": {
			filter: "grayscale(0%)",
			cursor: "pointer",
		},
	},
}))

const settings = {
	dots: false,
	arrows: true,
	infinite: true,
	speed: 800,
	slidesToShow: 10,
	slidesToScroll: 2,
	initialSlide: 0,
	autoplay: true,
	lazyLoad: true,
	responsive: [
		{
			breakpoint: 1200,
			settings: {
				slidesToShow: 4,
				slidesToScroll: 2,
				infinite: true,
				dots: false,
			},
		},
		{
			breakpoint: 700,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
				initialSlide: 2,
			},
		},
	],
}

interface Props {
	brandCategories: RawBrandCategory[]
}

const Brands = ({ brandCategories }: Props) => {
	const { classes } = useStyles()
	const { t } = useTranslation("home")

	return (
		<Box my={5} pb={5}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Link href="/brands">
						<h2>{t("popularBrands")}</h2>
					</Link>
					<Box className="custom-arrows-intermediate" px={2}>
						<Slider {...settings}>
							{brandCategories.map((brandCategory) => {
								const { id, nameAllLocales, base64svg, slug } = brandCategory
								const name = nameAllLocales.find(
									({ locale }) => locale === "de",
								).value

								return (
									<Box key={id}>
										<Link href={`/brands/${slug}`}>
											<Paper className={classes.paper}>
												<div className={classes.brandLogo}>
													<img
														src={base64svg}
														alt={name}
														style={{
															height: "70px",
															maxHeight: 60,
															width: "70px",
															objectFit: "contain",
														}}
													/>
												</div>
											</Paper>
										</Link>
									</Box>
								)
							})}
						</Slider>
					</Box>
				</Grid>
			</Grid>
		</Box>
	)
}

export default Brands
