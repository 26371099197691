import { makeStyles } from "makeStyles"
import { Container, Box, Hidden } from "@mui/material"
import Icon from "@mdi/react"
import { mdiHeadset, mdiCrown, mdiLock, mdiTruck } from "@mdi/js"

import useTranslation from "hooks/useTranslation"

import formatPrice from "@secureo/common/utils/forex/formatPrice"
import { flatFeesByCurrencyCode } from "@secureo/common/config/shippingZonesAndCosts"
import { CurrencyCode } from "@secureo/common/typings/Forex"

const useStyles = makeStyles()({
	format: {
		flexGrow: 1,
		display: "flex",
		justifyContent: "space-between",
		color: "#137596",
	},
	spacing: {
		alignItems: "center",
		fontFamily: "Saira, sans-serif",
		paddingTop: 8,
		opacity: 0.8,
		paddingBottom: 5,
	},
})

const Benefits = ({ currencyCode }: Props) => {
	const { classes } = useStyles()
	const { t } = useTranslation("home")

	return (
		<div>
			<Box pt={1}>
				<Hidden lgDown>
					<Container>
						<div className={classes.format}>
							<div className={classes.spacing}>
								<div style={{ display: "flex" }}>
									<Icon
										path={mdiHeadset}
										size={1}
										color="#7abfac"
										style={{ marginTop: "2px", marginRight: "5px" }}
									/>
									<div>
										<strong style={{ textTransform: "uppercase" }}>
											{t("expertAdvice")}
										</strong>{" "}
										<br />
										<div
											style={{
												fontSize: "13px",
												marginTop: "-4px",
												textTransform: "inherit",
											}}
										>
											{t("justAskAnExpert")}
										</div>
									</div>
								</div>
							</div>
							<div className={classes.spacing}>
								<div style={{ display: "flex" }}>
									<Icon
										path={mdiCrown}
										size={1}
										color="#7abfac"
										style={{ marginTop: "2px", marginRight: "5px" }}
									/>
									<div>
										<strong style={{ textTransform: "uppercase" }}>
											{t("pricePerformanceRatio")}
										</strong>{" "}
										<br />
										<div
											style={{
												fontSize: "13px",
												marginTop: "-4px",
												textTransform: "inherit",
											}}
										>
											{t("bestValueForBestPrice")}
										</div>
									</div>
								</div>
							</div>
							<div className={classes.spacing}>
								<div style={{ display: "flex" }}>
									<Icon
										path={mdiLock}
										size={1}
										color="#7abfac"
										style={{ marginTop: "2px", marginRight: "5px" }}
									/>
									<div>
										<strong style={{ textTransform: "uppercase" }}>
											{t("safeOnlinePayment")}
										</strong>{" "}
										<br />
										<div
											style={{
												fontSize: "13px",
												marginTop: "-4px",
												textTransform: "inherit",
											}}
										>
											{t("simpleAndSafeShopping")}
										</div>
									</div>
								</div>
							</div>
							<div className={classes.spacing}>
								<div style={{ display: "flex" }}>
									<Icon
										path={mdiTruck}
										size={1}
										color="#7abfac"
										style={{ marginTop: "2px", marginRight: "5px" }}
									/>
									<div>
										<strong style={{ textTransform: "uppercase" }}>
											{t("freeDelivery")}
										</strong>{" "}
										<br />
										<div
											style={{
												fontSize: "13px",
												marginTop: "-4px",
												textTransform: "inherit",
											}}
										>
											{t("startingFromAPriceOf")}{" "}
											{formatPrice(
												flatFeesByCurrencyCode[currencyCode]
													.flatFeeForSmallAmountThresholdGrossCents,
												"de-DE",
												currencyCode,
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</Container>
				</Hidden>
			</Box>
		</div>
	)
}

interface Props {
	currencyCode: CurrencyCode
}

export default Benefits
